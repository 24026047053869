import * as React from "react";
import {
    title,
    insideContainer,
    anchor
} from "./futurePlans.module.css";
import DecentralizedSocialPlatform from "./decentralizedSocialPlatform";
import HeartFoundation from "./heartFoundation";

const FuturePlans = ({}) => {
    return (
        <div>
            <a className={anchor} id="future-plans"/>
            <h1 className={title}>FUTURE PLANS</h1>
            <div className={insideContainer}>
                <DecentralizedSocialPlatform/>
                <HeartFoundation/>
            </div>
        </div>
    )
}

export default FuturePlans;
