import * as React from "react";

import {
    container,
    title,
    anchor
} from "./whitepaper.module.css"
import {ethhButton} from "./mainButton.module.css";
import WhitepaperDocument from "../documents/Whitepaper.pdf"

const WhitePaper = ({}) => {

    const handleClick = () => {
        window.open(WhitepaperDocument);
    }

    return (
        <div className={container}>
            <a className={anchor} id="whitepaper"/>
            <h1 className={title}>WHITEPAPER</h1>
            <div>

            </div>
            <div className={ethhButton} onClick={handleClick}>
                DOWNLOAD HERE!
            </div>
        </div>
    )
}

export default WhitePaper;
