import * as React from "react";

import {
    container
} from "./webThreeReport.module.css"
import {ethhButton} from "./mainButton.module.css";

const WebThreeReport = ({}) => {

    const handleClick = () => {
        window.open("https://consensys.net/reports/web3-report-q3-2021/");
    }

    return (
        <div className={container}>
            <div className={ethhButton} onClick={handleClick}>
                Web 3 Report!
            </div>
        </div>
    )
}

export default WebThreeReport;
