import * as React from "react";

import {
    container,
    firstImage,
    secondImage,
    thirdImage
} from "./socialMedia.module.css"
import Telegram from "../images/telegram.png"
import Instagram from "../images/instagram.png"
import Twitter from "../images/twitter-small.png"

const SocialMedia = ({}) => {

    const handleTelegramClick = () => {
        window.open("https://t.me/ethheart");
    }

    const handleInstagramClick = () => {
        window.open("https://instagram.com/ethheart");
    }

    const handleTwitterClick = () => {
        window.open("https://twitter.com/ethheart");
    }

    return (
        <div className={container}>
            <div className={firstImage} onClick={handleTelegramClick}>
                <img alt={"telegram"} src={Telegram} />
            </div>
            <div className={secondImage} onClick={handleInstagramClick}>
                <img alt={"instagram"} src={Instagram}/>
            </div>
            <div className={thirdImage}  onClick={handleTwitterClick}>
                <img alt={"intagram"} src={Twitter}/>
            </div>
        </div>
    )
}

export default SocialMedia;
