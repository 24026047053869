import * as React from "react";
import {
    title,
    insideContainer,
    insideContainerElement, center, futurePlansImage, bold,
    insideContainerElementTitle
} from "./futurePlans.module.css";
import MobileMarketing from "../../images/Mobile-Marketing.png";
import ArrowImage from "../../images/arrow.png";

const DecentralizedSocialPlatform = ({}) => {
    return (
        <div>
            <h1 className={insideContainerElementTitle}>Creating a decentralized social media platform</h1>
            <div className={insideContainerElement}>
                We believe that with the power of decentralization comes a great deal of responsibility,
                a responsibility to leave the world in a better place than we found it.
                We believe that we must do this for the future of our children, grandchildren, and future generations to come.
            </div>
            <br/>
            <div className={center}>
                <img alt="tree" className={futurePlansImage} src={MobileMarketing}/>
            </div>
            <div className={insideContainerElement}>
                <span className={bold}>The decentralized social media platform</span> we are creating will enable our token holders to collectively
                come together in new ways, collectively creating change for the world.
            </div>
            <div className={insideContainerElement}>
                <span className={bold}>Token holders will be able to:</span>
            </div>
            <div className={insideContainerElement}>
                <ul style={{listStyle: `url(${ArrowImage})`}}>
                    <li>communicate effectively through the platform</li>
                    <br/>
                    <li>trade NFTs</li>
                    <br/>
                    <li>share valuable data and incentive solutions to environmental issues whilst implementing an effective strategy and raise awareness on a global scale.</li>
                </ul>
            </div>
        </div>
    )
}

export default DecentralizedSocialPlatform;
