import * as React from 'react'
import Layout from "../components/layout";
import Favicon from 'react-favicon'
import FaviconImage from "../images/favicon.png"

const IndexPage = () => {
  return (
      <div>
          <Favicon url={FaviconImage} />
          <Layout/>
      </div>
  )
}

export default IndexPage
