import * as React from "react";

import DecentralizationImage from '../images/decentralization.png'
import MoneySackImage from '../images/moneySackRed.png'

import {
    container,
    gridContainer,
    title,
    imageDiv,
    image,
    textTokenomics,
    anchor
} from './tokenomics.module.css'
import arrowImage from "../images/arrow.png";


const Tokenomics = ({}) => {
    return (
        <div className={container}>
            <a className={anchor} id="tokenomics"/>
            <h1 className={title}>TOKENOMICS</h1>
            <div className={gridContainer}>
                <h2>DECENTRALIZED</h2>
                <h2>COMMUNITY FOCUSED</h2>
                <div className={imageDiv}>
                    <img className={image} alt="Decentralization" src={DecentralizationImage}/>
                </div>
                <div className={imageDiv}>
                    <img className={image} alt="Money Sack" src={MoneySackImage}/>
                </div>
                <div className={textTokenomics}>
                    The future of asset trading is decentralized - our decentralized protocols give our token holders complete control of their crypto assets without the need for an intermediary.
                </div>
                <div className={textTokenomics}>
                    The whole principle behind decentralization and cryptocurrency in general is the basis of moving together to reach the common goal of a collaborative economy.
                    <br/>
                    Our greatest power is our community, and we believe that through maximising efficiency in innovation we can bring like-minded individuals to create a think-tank for the greater good.
                </div>
            </div>
            <div className={gridContainer}>
                <h2>SECURE PAYMENTS</h2>
                <h2>NFT MARKETPLACE</h2>
                {/*<div className={imageDiv}>*/}
                {/*    <img className={image} alt="Decentralization" src={DecentralizationImage}/>*/}
                {/*</div>*/}
                {/*<div className={imageDiv}>*/}
                {/*    <img className={image} alt="Money Sack" src={MoneySackImage}/>*/}
                {/*</div>*/}
                <div className={textTokenomics}>
                    Industry standard public-key cryptography tools means that the code is well-tested thus effective, reliable and efficient.
                    <br/>
                    All transactions on the network are available to the public, as they are on the blockchain.
                </div>
                <div className={textTokenomics}>
                    A peer-to-peer marketplace exclusively for ETHH token holders. Buy, sell, and auction rare digital items.
                </div>
            </div>
            <div className={gridContainer}>
                <h2>SMART CONTRACT INTEGRATION</h2>
                <h2>THE GREAT RESET</h2>
                {/*<div className={imageDiv}>*/}
                {/*    <img className={image} alt="Decentralization" src={DecentralizationImage}/>*/}
                {/*</div>*/}
                {/*<div className={imageDiv}>*/}
                {/*    <img className={image} alt="Money Sack" src={MoneySackImage}/>*/}
                {/*</div>*/}
                <div className={textTokenomics}>
                    Utilise ETHH with decentralized apps and the creation of smart contracts.
                    <br/>
                    Send ETHH overseas instantaneously for a minimal costs.
                    <br/>
                    Spend ETHH online without the need for a physical credit/debit card.
                    <br/>
                    Use the NFT platform to trade  rare collectibles and pay using ETHH.
                    <br/>
                    Benefit from the best exchange rates, lowest fees, and instant processing speed.
                    <br/>
                    Join our community to be notified of the latest news, offers, and airdrops. (Twitter, Instagram, and DeSo).
                    <br/>
                    Use ETHH for integration with smart contracts and decentralized applications.
                </div>
                <div className={textTokenomics}>
                    The “Great Reset” is going to be a massive reset of the global financial system, among a complete reset of many different aspects of society.
                    <br/>
                    Cryptocurrency, and blockchain technologies are what will propel us into the 4th industrial revolution.
                </div>
            </div>
            <div className={gridContainer}>
                <h2>OUR LONG-TERM VISION</h2>
                <h2>TOKENOMICS</h2>
                {/*<div className={imageDiv}>*/}
                {/*    <img className={image} alt="Decentralization" src={DecentralizationImage}/>*/}
                {/*</div>*/}
                {/*<div className={imageDiv}>*/}
                {/*    <img className={image} alt="Money Sack" src={MoneySackImage}/>*/}
                {/*</div>*/}
                <div className={textTokenomics}>
                    We are focused on building a community to effectively equip ourselves for the coming great reset
                    where we can bring like-minded individuals together to utilise blockchain technologies
                    for the greater-good of humanity.
                </div>
                <div className={textTokenomics}>
                        10,000,000,000 Fixed Supply.
                    <br/>
                        1% of all transactions are redistributed to token holders.
                    <br/>
                        1% of transactions go towards marketing to increase the tokens exposure.
                </div>
            </div>
        </div>
    )
}

export default Tokenomics;
