import * as React from 'react'

import {
    logoMainContainer,
    logoImage,
    logoSecondLineContainer,
    item1
} from './logo.module.css'

import Heart from '../images/ETHH.png'

const Logo = ({}) => {
    return (
        <div className={logoMainContainer}>
            <div className={item1}>
                <div>ETHEREUM</div>
            </div>
            <div className={logoSecondLineContainer}>
                <div>HEART</div>
                <img alt="Logo" className={logoImage} src={Heart}/>
            </div>
        </div>
    )
}

export default Logo
