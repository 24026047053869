import * as React from 'react'

import {
    container,
    bold,
    ethhButton,
    mainText
} from './mainButton.module.css'

const MainButton = ({}) => {

    const handleClick = () => {
        window.open("https://uniswap.org");
    }

    return (
        <div className={container}>
            <div className={mainText}>
                <span className={bold}>SHIFT</span> YOUR PERSPECTIVE
            </div>
            <div className={ethhButton} onClick={handleClick}>
                BUY ETHH
            </div>
        </div>
    )
}

export default MainButton;
