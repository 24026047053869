import * as React from "react"

import {
    text,
    bold
} from "./copyright.module.css";


const Copyright = ({}) => {
    return (
        <div className={text}>
            Copyright 2022 © <span className={bold}>Ethereum heart</span>
        </div>
    )
}

export default Copyright;
