import * as React from "react";
import {
    title,
    insideContainer,
    insideContainerElement,
    futurePlansImage,
    center, bold, insideContainerElementUnique,
    insideContainerElementTitle
} from "./futurePlans.module.css";
import ArrowImage from "../../images/arrow.png"
import Tree from "../../images/Tree-life-pana-white.png";

const HeartFoundation = ({}) => {
    return (
        <div>
            <h1 className={insideContainerElementTitle}>The Ethereum Heart Foundation</h1>
            <div className={insideContainerElement}>
                This mission of the Ethereum Heart Foundation is focusing on building a better world by allowing our community members to unite and vote in the charities we will give quarterly donations to.
            </div>
            <div className={center}>
                <img alt="tree" className={futurePlansImage} src={Tree}/>
            </div>
            <div className={insideContainerElement}><span className={bold}>Some potential charities we will use include:</span></div>
            <div className={insideContainerElement}>
                <ul style={{listStyle: `url(${ArrowImage})`}}>
                    <li>Greenpeace Fund</li>
                    <br/>
                    <li>Friends of the Earth</li>
                    <br/>
                    <li>Rainforest Alliance</li>
                    <br/>
                    <li>WWF</li>
                    <br/>
                    <li>the Clean Air Task Air Force.</li>
                    <br/>
                    <br/>
                </ul>
            </div>
            <div className={insideContainerElementUnique}>
                <span className={bold}>All charities will be environmentally orientated and focused on creating a better world.</span>
            </div>
        </div>
    )
}

export default HeartFoundation;
