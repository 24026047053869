import * as React from 'react'
import { Link } from 'gatsby'

import {
    container,
    navLinks,
    navLinkItem,
    navLinkText,
    navDiv,
    mainPage,
    topNav,
    navButton,
    hamburger
} from './layout.module.css'
import Logo from "./logo";
import MainButton from "./mainButton";
import Tokenomics from "./tokenomics";
import Roadmap from "./roadmap";
import HowToBuyEthh from "./howToBuyEthh";
import {useState} from "react";
import blackImage from "../images/AdobeStock_smaller.jpeg"
import FuturePlans from "./futureplans/futurePlans";
import Copyright from "./copyright";
import SocialMedia from "./socialMedia";
import WhitePaper from "./whitepaper";
import WebThreeReport from "./webThreeReport";

const Layout = ({ pageTitle }) => {

    const [navbarOpen, setNavbarOpen] = useState(false)

    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
    }

    return (
        <div className={container}>
            <title>{pageTitle}</title>
                <div className={mainPage}>
                    <div className={navDiv}>
                        <Logo/>
                        <nav className={topNav}>
                            {/*<label className={hamburger} htmlFor="hamburger">&#9776;</label>*/}
                            {/*<input className={hamburgerCheckbox} type="checkbox" id="hamburger"/>*/}
                            <ul className={navLinks}>
                                <li className={navLinkItem}>
                                    <Link to="#tokenomics" className={navLinkText}>
                                        TOKENOMICS
                                    </Link>
                                </li>
                                <li className={navLinkItem}>
                                    <Link to="#roadmap" className={navLinkText}>
                                        ROADMAP
                                    </Link>
                                </li>
                                <li className={navLinkItem}>
                                    <Link to="#future-plans" className={navLinkText}>
                                        FUTURE&nbsp;PLANS
                                    </Link>
                                </li>
                                <li className={navLinkItem}>
                                    <Link to="#whitepaper" className={navLinkText}>
                                        WHITEPAPER
                                    </Link>
                                </li>
                            </ul>

                            <div className={navButton} onClick={handleToggle}>
                                <div className={hamburger}/>
                                <div className={hamburger}/>
                                <div className={hamburger}/>
                            </div>
                        </nav>
                    </div>
                </div>
            <div style={Object.assign({},
                {backgroundImage: `url(${blackImage})`},
                {height: "100%"},
                {backgroundSize: "cover"},
                {backgroundPosition: "center"}
            )}>
                <MainButton/>
            </div>
            <Tokenomics/>
            <Roadmap/>
            <FuturePlans/>
            <HowToBuyEthh/>
            <WhitePaper/>
            <WebThreeReport/>
            <SocialMedia/>
            <Copyright/>
        </div>
    )
}
export default Layout
