import * as React from "react";

import {
    container,
    formattedText,
    title,
    link,
    contractAddress
} from "./howToBuyEthh.module.css"

const HowToBuyEthh = ({}) => {
    return (
        <div className={container}>
            <h1 className={title}>How to buy ETHH:</h1>
            <div className={formattedText}>
                <ol>
                    <li>Download Metamask:</li>
                    <div>For mobile: Download the Metamask App from the App or Google Play Store’s.</div>
                    <div>For pc:Add the Metamask browser extension for Chrome, Brave, Firefox or Edge.</div>
                    <div>Follow the in-app tutorial to get started.</div>
                    <br/>
                    <li>Write down your seed phrase somewhere safe. Do not store this electronically.</li>
                    <br/>
                    <li>Buy Ethereum:</li>
                    <div>Buy Ethereum using your preferred exchange.</div>
                    <br/>
                    <li>Transfer to Metamask:</li>
                    <div>Transfer your Ethereum to Metamask.</div>
                    <br/>
                    <li>Connect to Uniswap:</li>
                    <div>Find the official contract address here: </div>
                    <a className={link} href="https://etherscan.io/address/0xb55088e553ddeb711a914b4c698e9582cd6d3ac3">etherscan address</a>
                    <div>(Copy contract on top right corner).</div>
                    <br/>
                    <li>Swap:</li>
                    <div>Select from: Ethereum - ETH. To: Paste in official ETHH contract address and click import.</div>
                    <div>Put in the ETH amount you wish to swap; make sure you have enough ETH for the transaction fee (edit the fee to make sure the transaction runs smoothly.</div>
                </ol>
                <div>Ethereum Contract Address: <span className={contractAddress}>0xb55088e553ddeb711a914b4c698e9582cd6d3ac3</span></div>
            </div>
        </div>
    )
}

export default HowToBuyEthh;
