import * as React from "react";

import RoadmapImage from '../images/RoadmapFinal.png'
import {
    container,
    roadmapImageStyle,
    anchor
} from './roadmap.module.css'
import {title} from "./tokenomics.module.css";


const Roadmap = ({}) => {
    return (
        <div className={container}>
            <a className={anchor} id="roadmap"/>
            <h1 className={title}>ROADMAP</h1>
            <img className={roadmapImageStyle} alt="Roadmap" src={RoadmapImage} />
        </div>
    )
}

export default Roadmap;
